<template>
  <div>
    <t-button class="p-2"
              variant="link"
              @click.stop="handleClick()">
      <fluency-icon type="chevronRight" class="expand-icon" :class="{ 'rotate-right-90': detailsShowing }"></fluency-icon>
    </t-button>
    <slot>{{value}}</slot>
  </div>
</template>

<script setup>

const props = defineProps({
  toggleDetails: {
    type: Function
  },
  detailsShowing: {
    type: Boolean
  },
  value: {
    type: String
  }
})
const emits = defineEmits(['toggle-details'])

const handleClick = () => {
  if (props.toggleDetails) {
    props.toggleDetails()
  }
  emits('toggle-details')
}
</script>

<style lang='scss' scoped>
.expand-icon {
  transition: transform 200ms;
}
</style>
